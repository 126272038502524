import exologAdmin from '@/exologAdmin'
import $$ from 'jquery'
import overlay from '@/common/overlay'
//some common legasy code and style for 'sitePopup'(popup.js)
import '@/common/sitePopup'
import '@/assets/scss/global.scss'


if (process.env.NODE_ENV !== 'production') {
    console.info('-==development mode==-');
} else {
    //console.info('-==production mode==-');
}
let exologLoader = {
    async init() {
        exologAdmin.init()

        if ($$('meta[name="exologEditor"]').length) {
            await this.initExologEditor()
        }
    },
    async initExologEditor() {
        overlay.show('body')
        let exologEditor = (await import(/* webpackChunkName: "exolog-editor" */'@/exologEditor')).default
        exologEditor.init()
    },
}

$$(_ => {
    exologLoader.init()
})
